<template>
  <div class="rule">
    <!--  标签页-->
    <el-tabs v-model="activeName" class="el-nav" @tab-click="handleClick" style="min-height: unset">
      <el-tab-pane label="回播管理" name="first"> </el-tab-pane>
      <el-tab-pane label="回播详情" name="two">
        <div class="video space-between">
          <div class="video-l">
            <div class="video-l-top space-between vertical-center">
              <div class="vertical-center">
                <img :src="creationData.user && creationData.user.avatar" class="video-l-top-img" alt="" />
                <span class="text-s white-space" style="width: 200px;">{{ creationData.user && creationData.user.nickname }}</span>
              </div>

              <div class="video-l-top-r vertical-center">房间号：{{ creationData && creationData.live_room_id }}</div>
            </div>
            <div class="video-l-text white-space-2 text-s">
              {{ creationData && creationData.title }}
            </div>
            <play-video :file_id="creationData.content && creationData.content.MediaUrl" class="play-video"></play-video>
          </div>
          <div class="video-r">
            <div class="video-r-title vertical-center space-between">
              <div class="">{{ creationData.user && creationData.user.nickname }}更多直播</div>
              <div class="flex-nowrap">
                <div class="list-item-bottom-l vertical-center">
                  <img src="~assets/images/index/peo.png" class="peo" alt="" />
                  {{ creationData && creationData.looker_count ? (creationData.looker_count > 9999 ? (creationData.looker_count / 10000).toFixed(2) + 'W' : creationData.looker_count) : 0 }}
                </div>
                <div class="dz">
                  <img src="~assets/images/data/dz.png" alt="" style="width: 24px;height: 24px;margin-right: 5px;" />
                  {{ creationData && creationData.good_count ? (creationData.good_count > 9999 ? (creationData.good_count / 10000).toFixed(2) + 'W' : creationData.good_count) : 0 }}点赞
                </div>
              </div>
            </div>
            <div class="video-r-list" v-if="livesData.data && livesData.data.length">
              <div class="video-r-list-item align-center" v-for="(item, index) in livesData.data" :key="index" :class="{ act: act === item.id }" @click="chooseVideo(item)">
                <div class="video-r-list-item-center align-center">
                  <img :src="item.cover_url" alt="" class="bg-img" v-if="item.cover_url" />
                  <img src="~assets/images/data/videobg.jpg" alt="" class="bg-img" v-else />
                  <img src="~assets/images/data/play.png" class="play" alt="" />
                </div>
                <!--                <div class="mengceng"></div>-->
                <div class="mengceng-outside"></div>
                <div class="video-del">
                  <img
                    src="~assets/images/index/edit.png"
                    class="video-del-img"
                    alt=""
                    @click.stop="(isDialogvideo = true), (chaptersForm = JSON.parse(JSON.stringify(item))), (cover_url_data[0] = item.cover_url)"
                  />
                  <img
                    src="~assets/images/index/edit-h.png"
                    class="video-del-img-h"
                    alt=""
                    @click.stop="(isDialogvideo = true), (chaptersForm = JSON.parse(JSON.stringify(item))), (cover_url_data[0] = item.cover_url)"
                  />
                </div>
              </div>

              <!--              <div class="video-r-list-item align-center">-->
              <!--                <div class="video-r-list-item-center align-center">-->
              <!--                  <img src="~assets/images/data/play.png" class="play"  alt="">-->
              <!--                </div>-->
              <!--&lt;!&ndash;                <img src="~assets/images/data/del.png" class="video-del" alt="">&ndash;&gt;-->
              <!--              </div>-->
              <!--              <div class="video-r-list-item align-center">-->
              <!--                <div class="video-r-list-item-center align-center">-->
              <!--                  <img src="~assets/images/data/play.png" class="play"  alt="">-->
              <!--                </div>-->
              <!--&lt;!&ndash;                <img src="~assets/images/data/del.png" class="video-del" alt="">&ndash;&gt;-->
              <!--              </div>-->
              <!--              <div class="video-r-list-item align-center">-->
              <!--                <div class="video-r-list-item-center align-center">-->
              <!--                  <img src="~assets/images/data/play.png" class="play"  alt="">-->
              <!--                </div>-->
              <!--&lt;!&ndash;                <img src="~assets/images/data/del.png" class="video-del" alt="">&ndash;&gt;-->
              <!--              </div>-->
            </div>
            <div class="video-r-list" v-else>
              <div style="width: 100%;height: 100%">
                <empty-prompt></empty-prompt>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="comment-right">
      <div class="total space-between vertical-center ">
        <div class="total-left">
          全部评论
          <span class="total-left-text1">
            （<span class="total-left-text">{{ comments.meta ? comments.meta.total : 0 }}</span
            >条）</span
          >
        </div>
      </div>
      <comment :uuid="creationData.uuid" :comment="comments.data" :isreply="isReply" type="replay" v-if="comments.data && comments.data.length" @commentsHandle="commentsChildren"></comment>
      <empty-prompt v-else style="margin-bottom: 120px"></empty-prompt>
    </div>

    <el-dialog class="dialog" title="编辑回播" :visible.sync="isDialogvideo" v-if="isDialogvideo" top="calc( 50vh - 150px )" width="540px" :destroy-on-close="true">
      <!--      表单-->
      <el-form label-position="right" label-width="100px" :model="chaptersForm" :rules="rules" ref="chaptersForm" class="form">
        <el-form-item label="标题" prop="title">
          <el-input placeholder="请输入标题名称" maxlength="15" v-model="chaptersForm.title"></el-input>
        </el-form-item>
        <el-form-item label="上传封面图" prop="cover_url">
          <upload-file @successUrl="imgUrl" :limit="1" :folder="'live'" :default_value="cover_url_data" :url="url"></upload-file>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input placeholder="请输入描述" maxlength="150" type="textarea" :rows="4" v-model="chaptersForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="isDialogvideo = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="chaptersCreate">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Comment from 'components/comment';
import PlayVideo from 'components/playVideo';
import EmptyPrompt from 'components/EmptyPrompt';
import uploadFile from 'components/uploadFile';
import _ from 'lodash';

export default {
  name: 'Index',
  components: {
    Comment,
    PlayVideo,
    EmptyPrompt,
    uploadFile,
  },
  data() {
    return {
      url: 'image/browse',
      isEltab: 1,
      isAdd: true,
      name: '',
      value: '',
      //观看
      isDialogVisible: false,
      //弹幕
      isdmDialogVisible: false,
      value1: '',
      activeName: 'two',
      input: '',
      creationData: {}, // 接口数据
      comments: [], //评论
      livesData: [], //视频列表
      act: '',
      chaptersForm: [],
      chaptersRules: [],
      isDialogvideo: false,
      rules: {
        cover_url: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题名称', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入描述', trigger: 'blur' }],
      },
      cover_url_data: [],
      isReply: true,
    };
  },
  created() {
    // this.queryFun()
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      let url = this.$api.lives + `?&user_uuid=${this.$route.query.id}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          if (res.data.data.data[0]) {
            this.creationData = res.data.data.data[0];
            this.act = res.data.data.data[0].id;
          }
          this.livesData = res.data.data;
          this.commentList();
        }
      });
    },
    //评论
    commentList() {
      let url = this.$api.comments + `?type=replay&uuid=${this.creationData.uuid}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.comments = res.data.data;
        }
      });
    },
    handleClick(tab) {
      if (tab.name === 'first') {
        this.$router.push('/replay/list');
      }
    },
    // 图片上传的
    imgUrl(data) {
      // this.$set(this.chaptersForm,'cover_url',data[data.length - 1])
      if (!data[data.length - 1]) {
        data[data.length - 1] = '';
      }
      this.chaptersForm.cover_url = data[data.length - 1];
      this.$refs.chaptersForm.clearValidate('cover_url');
    },
    // 评价更新
    commentsChildren(data) {
      if (data === 'upload') {
        this.commentList(this.uuid, this.commentType);
      }
    },
    // 编辑
    chaptersCreate: _.debounce(function() {
      this.$refs.chaptersForm.validate((valid) => {
        if (valid) {
          let that = this;
          let url = this.$api.lives + `/${this.chaptersForm.uuid}`;
          let data = this.chaptersForm;
          this.$http
            .put(url, data, true)
            .then((res) => {
              if (res.data.success) {
                this.$message.success('修改成功');
                this.livesData.data.forEach(function(item, index) {
                  if (item.id == that.chaptersForm.id) {
                    that.livesData.data[index] = that.chaptersForm;
                    if (item.id == that.creationData.id) {
                      that.creationData = that.chaptersForm;
                    }
                  }
                });
                this.isDialogvideo = false;
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((error) => {
              this.$message.error(error.response.data.message);
            });
        } else {
          return false;
        }
      });
    }, 500),
    // 选择视频
    chooseVideo: _.debounce(function(item) {
      this.creationData = item;
      this.act = item.id;
      this.commentList();
    }, 500),
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/replay/index.less';
</style>
